import React from "react";
import {Box, Button, Text, VStack, Icon, Flex, FormControl, FormLabel, Input, Switch, SimpleGrid} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { FaRegFileAlt } from "react-icons/fa";
import RegionMap from "./RegionMap/RegionMap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";

const RegionsList = ({startDate,endDate,selectedGeographiesData = [],getSelectedGeographies,getStartDate,getEndDate,includeImpactStories,}) => {
    const removeGeography = (id) => {
        getSelectedGeographies(selectedGeographiesData.filter((geo) => geo.id !== id));
    };

    const handleGeographySelection = (event) => {
        const newLocation = {
            id: Date.now(),
            region: event.region,
            regionId: event.regionId,
            country: event.country,
            countryId: event.countryId,
            state: event.state,
            stateId: event.stateId,
            city: event.city,
            cityId: event.cityId,
        };
        getSelectedGeographies([...selectedGeographiesData, newLocation]);
    };
    const uniqueGeographies = Array.from(
        new Map(selectedGeographiesData.map((geo) => [geo.id, geo])).values()
    );
    return (
        <>
            <RegionMap
                type="eir"
                getSelectedGeographies={handleGeographySelection}
                selectedData={selectedGeographiesData}
            />

            <FormControl mt="15px">
                <FormLabel fontWeight="600">Selected Geographies</FormLabel>
                <Box border="1px solid #ddd" borderRadius="4px" maxH="150px" overflowY="auto" p="8px">
                    {uniqueGeographies.length === 0 ? (
                        <VStack mt={4} spacing={2} textAlign="center">
                            <Icon as={FaRegFileAlt} boxSize={8} color="gray.400" />
                            <Text color="gray.500">No geographies selected</Text>
                        </VStack>
                    ) : (
                        uniqueGeographies.map((geo) => (
                            <Flex
                                key={geo.id ?? geo.regionId}
                                bg="#cbcbcb"
                                p="5px"
                                borderRadius="md"
                                align="center"
                                justify="space-between"
                                mb={2}
                                color="#3e4b5b"
                            >
                                <Text flex="1" mb={0}>
                                    <Text as="span" fontWeight="bold">{geo?.region}</Text>
                                    {[geo?.country, geo?.state, geo?.city]
                                        .filter(Boolean)
                                        .length > 0 && ` / ${[geo?.country, geo?.state, geo?.city].filter(Boolean).join(" / ")}`}
                                </Text>
                                <Button size="xs" onClick={() => removeGeography(geo.id)} bg="transparent" _hover={{ bg: "gray.600" }}>
                                    <CloseIcon boxSize={2} color="white" />
                                </Button>
                            </Flex>
                        ))
                    )}
                </Box>
            </FormControl>

            <FormControl mt="10px">
                <FormLabel fontWeight="600">Select report start and end date</FormLabel>
                <SimpleGrid columns={[2, null, 3]} spacing={2}>
                    <Box border="1px solid #ddd" borderRadius="4px" p="8px">
                        <FormControl>
                            <FormLabel fontWeight="600">Report Start Date</FormLabel>
                            <DatePicker
                                selected={startDate}
                                onChange={(date) => getStartDate(date ? format(date, "MM-dd-yyyy") : "")}
                                dateFormat="MM-dd-yyyy"
                                placeholderText="MM-DD-YYYY"
                                customInput={<Input />}
                            />
                        </FormControl>
                    </Box>

                    <Box border="1px solid #ddd" borderRadius="4px" p="8px">
                        <FormControl>
                            <FormLabel fontWeight="600">Report End Date</FormLabel>
                            <DatePicker
                                selected={endDate}
                                onChange={(date) => getEndDate(date ? format(date, "MM-dd-yyyy") : "")}
                                dateFormat="MM-dd-yyyy"
                                placeholderText="MM-DD-YYYY"
                                customInput={<Input />}
                            />
                        </FormControl>
                    </Box>

                    <Box border="1px solid #ddd" borderRadius="4px" p="8px">
                        <FormControl display="flex" alignItems="center">
                            <FormLabel mb="0" fontWeight="600">Include Impact Stories:</FormLabel>
                            <Switch
                                isChecked={includeImpactStories}
                                onChange={() => includeImpactStories(!includeImpactStories)}
                            />
                        </FormControl>
                    </Box>
                </SimpleGrid>
            </FormControl>
        </>
    );
};

export default RegionsList;


