import React, { useEffect, useState } from 'react';
import Styles from './reports.module.css';
import { 
    Box, Button, Text, VStack, Icon, SimpleGrid, Flex, HStack 
} from "@chakra-ui/react";
import { FaRegFileAlt } from "react-icons/fa";
import { useDisclosure } from "@chakra-ui/react";
import API from '../../../Services/API';
import Delete from './Delete/Delete';
import LoaderSpinner from '../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import EditReport from './ViewReport/EditReport/EditReport';
import ViewReport from './ViewReport/ViewReport';

const ReportsList = ({ updateTrigger }) => {
    const { isOpen, onOpen } = useDisclosure();
    const token = window.localStorage.getItem("accessToken");
    const [reports, setReports] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState(null);

    useEffect(() => {
        getUpdated();
    }, [updateTrigger]);

    const getUpdated = () => {
        setIsLoading(true);
        API.get(`/custom-country-report`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => {
            setIsLoading(false);
            const sortedReports = response.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
            setReports(sortedReports);
        })
        .catch(error => {
            console.error("Error fetching reports:", error);
            setIsLoading(false);
            setMessage("Failed to fetch reports. Please try again.");
        });
    };

    return (
        <>
            {isLoading && <LoaderSpinner />}
            <Box className='clearfix'></Box>
            {reports.length === 0 && !isLoading ? (
                <VStack spacing={2} mt={12} textAlign="center">
                    <Icon as={FaRegFileAlt} boxSize={10} color="gray.400" />
                    <Text marginBottom="0" color="gray.500">
                        No executive impact reports currently
                    </Text>
                    <Text color="blue.500" cursor="pointer" marginBottom="0" onClick={onOpen}>
                        Click here
                    </Text>
                </VStack>
            ) : (
                <SimpleGrid columns={[2, null, 3]} spacing="40px" mt="10px" borderTop="2px solid #cbd5e0" pt="10px">
                    {reports.map(report => (
                        <Box key={report.id} className={Styles.reportContainer}>
                            <Box className={Styles.header}>
                                <Box className={Styles.headerLeft}>SIIO Action Office Custom Report_{report.title}</Box>
                                <Box className={Styles.headerRight}>
                                    <EditReport id={report.id} title={report.title} getUpdated={getUpdated} />
                                    <Delete id={report.id} title={report.title} getUpdated={getUpdated} />
                                </Box>
                            </Box>
                            <Box className={Styles.contentContainer} position="relative" minH="90px">
                                <Box position="relative" top="50%" transform="translateY(-50%)">
                                    From <Text as="span" fontWeight="600">{report.start_date ?? "N/A"}</Text> 
                                    {" "}To{" "} 
                                    <Text as="span" fontWeight="600">{report.end_date ?? "Present"}</Text>
                                </Box>
                            </Box>
                            <Box className={Styles.footer}>
                                <Flex justify="center" mt={4}>
                                    <HStack spacing={4}>
                                        <ViewReport id={report.id} title={`SIIO Action Office Custom Report_${report.title}_${report.created_at}`} />
                                    </HStack>
                                </Flex>
                            </Box>
                        </Box>
                    ))}
                </SimpleGrid>
            )}
        </>
    );
};

export default ReportsList;
