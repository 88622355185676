import React, { useEffect, useState, useCallback } from 'react';
import API from '../../../../../../Services/API';
import { Box, FormControl, FormLabel } from '@chakra-ui/react';

const Pillars = (props) => {
    const token = window.localStorage.getItem("accessToken");

    const [tags, setTags] = useState([]);
    const [pillars, setPillars] = useState([]);
    const [actions, setActions] = useState([]);
    const [metrics, setMetrics] = useState([]);

    // Fetch Tags, Pillars, Actions, and Metrics on Mount
    useEffect(() => {
        if (!props.id) return;
    
        API.get(`/custom-country-report?report_id=${props.id}`, {
            headers: { Authorization: `Bearer ${token}` },
        })
        .then((pillarsResponse) => {
            if (pillarsResponse.data.length > 0) {
                const selectedTagIds = pillarsResponse.data[0]?.tags?.map(t => t.id) || [];
                const selectedPillarIds = pillarsResponse.data[0]?.pillars?.map(p => p.id) || [];
                const selectedActionIds = pillarsResponse.data[0]?.actions?.map(a => a.id) || [];
                const selectedMetricIds = pillarsResponse.data[0]?.metrics?.map(m => m.id) || [];
    
                // Fetch Tags
                API.get(`/tag-detail/`, { headers: { Authorization: `Bearer ${token}` } })
                .then(response => {
                    setTags(response.data.results.map(tag => ({
                        ...tag,
                        selected: selectedTagIds.includes(tag.id),
                    })));
                })
                .catch(error => console.error("Error fetching tags:", error));
    
                // Fetch Pillars
                API.get(`/sio-pillar`, { headers: { Authorization: `Bearer ${token}` } })
                .then(response => {
                    setPillars(response.data.map(pillar => ({
                        ...pillar,
                        selected: selectedPillarIds.includes(pillar.id),
                    })));
                })
                .catch(error => console.error("Error fetching pillars:", error));
    
                // Fetch Actions
                API.get(`/multi-action-pillar?pillar_id=${JSON.stringify(selectedPillarIds)}`, { headers: { Authorization: `Bearer ${token}` } })
                .then(response => {
                    setActions(response.data.map(action => ({
                        ...action,
                        selected: selectedActionIds.includes(action.id),
                    })));
                })
                .catch(error => console.error("Error fetching actions:", error));
    
                // Construct dynamic query params for metrics
                let queryParams = [];
                if (selectedPillarIds.length) queryParams.push(`pillar_id=${selectedPillarIds.join(",")}`);
                if (selectedActionIds.length) queryParams.push(`action_id=${selectedActionIds.join(",")}`);
                if (selectedTagIds.length) queryParams.push(`tags_id=${selectedTagIds.join(",")}`);
    
                if (queryParams.length > 0) {
                    API.get(`/metric-list-report?${queryParams.join("&")}`, { headers: { Authorization: `Bearer ${token}` } })
                    .then(response => {
                        setMetrics(response.data.map(metric => ({
                            ...metric,
                            selected: selectedMetricIds.includes(metric.id),
                        })));
                    })
                    .catch(error => console.error("Error fetching metrics:", error));
                } else {
                    setMetrics([]);
                }
            }
        })
        .catch(error => console.error("Error fetching report:", error));
    }, [props.id]);

    // Toggle Selection Handler
    const toggleSelection = useCallback((id, setState) => {
        setState(prev => prev.map(item =>
            item.id === id ? { ...item, selected: !item.selected } : item
        ));
    }, []);

    // Pass selected items to parent component
    useEffect(() => {
        const selectedTags = tags.filter(t => t.selected).map(t => t.id);
        const selectedPillars = pillars.filter(p => p.selected).map(p => p.id);
        const selectedActions = actions.filter(a => a.selected).map(a => a.id);
        const selectedMetrics = metrics.filter(m => m.selected).map(m => m.id);

        props.getTagsList(selectedTags);
        props.getPillarsList(selectedPillars);
        props.getActionsList(selectedActions);
        props.getMetricsList(selectedMetrics);
    }, [tags, pillars, actions, metrics]);

    return (
        <>
            {/* Tags Section */}
            <FormControl mb="15px">
                <FormLabel fontWeight="600">Tags</FormLabel>
                <Box borderWidth="1px" borderColor="gray.300" borderRadius="md" maxH="150px" overflowY="auto" p="2" textTransform={'capitalize'}>
                    {[...tags].sort((a, b) => a.name.localeCompare(b.name)).map(tag => (
                        <div key={tag.id} onClick={() => toggleSelection(tag.id, setTags)}
                            style={{ display: "flex", alignItems: "center", marginBottom: "8px", cursor: "pointer" }}>
                            {tag.selected && <span style={{ color: "green", marginRight: "8px", fontWeight: "bold" }}>✔</span>}
                            <span>{tag.name}</span>
                        </div>
                    ))}
                </Box>
            </FormControl>

            {/* Pillars Section */}
            <FormControl mt="15px" mb="15px">
                <FormLabel fontWeight="600">Pillars</FormLabel>
                <Box borderWidth="1px" borderColor="gray.300" borderRadius="md" maxH="150px" overflowY="auto" p="2" textTransform={'capitalize'}>
                    {[...pillars].sort((a, b) => a.name.localeCompare(b.name)).map(pillar => (
                        <div key={pillar.id} onClick={() => toggleSelection(pillar.id, setPillars)}
                            style={{ display: "flex", alignItems: "center", marginBottom: "8px", cursor: "pointer" }}>
                            {pillar.selected && <span style={{ color: "green", marginRight: "8px", fontWeight: "bold" }}>✔</span>}
                            <span>{pillar.name}</span>
                        </div>
                    ))}
                </Box>
            </FormControl>

            {/* Actions Section */}
            <FormControl mb="15px">
                <FormLabel fontWeight="600">Action Teams</FormLabel>
                <Box borderWidth="1px" borderColor="gray.300" borderRadius="md" maxH="150px" overflowY="auto" p="2" textTransform={'capitalize'}>
                    {[...actions].sort((a, b) => a.name.localeCompare(b.name)).map(action => (
                        <div key={action.id} onClick={() => toggleSelection(action.id, setActions)}
                            style={{ display: "flex", alignItems: "center", marginBottom: "8px", cursor: "pointer" }}>
                            {action.selected && <span style={{ color: "green", marginRight: "8px", fontWeight: "bold" }}>✔</span>}
                            <span>{action.name}</span>
                        </div>
                    ))}
                </Box>
            </FormControl>

            {/* Metrics Section */}
            <FormControl mb="15px">
                <FormLabel fontWeight="600">Metrics</FormLabel>
                <Box borderWidth="1px" borderColor="gray.300" borderRadius="md" maxH="150px" overflowY="auto" p="2" textTransform={'capitalize'}>
                    {[...metrics].sort((a, b) => a.name.localeCompare(b.name)).map(metric => (
                        <div key={metric.id} onClick={() => toggleSelection(metric.id, setMetrics)}
                            style={{ display: "flex", alignItems: "center", marginBottom: "8px", cursor: "pointer" }}>
                            {metric.selected && <span style={{ color: "green", marginRight: "8px", fontWeight: "bold" }}>✔</span>}
                            <span>{metric.name}</span>
                        </div>
                    ))}
                </Box>
            </FormControl>
        </>
    );
};

export default Pillars;
