import React, { useEffect, useState, } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { EditIcon } from '@chakra-ui/icons';
import { 
    Box, Button, Text, Drawer, DrawerOverlay, DrawerContent, 
    DrawerHeader, DrawerBody, DrawerCloseButton, useDisclosure, 
    FormControl, FormLabel, Input, Flex, 
    DrawerFooter
} from "@chakra-ui/react";
import API from '../../../../../Services/API';
import EirInnerComponent from '../../../EirInnerComponent/EirInnerComponent';
import RegionsList from './RegionsList/RegionsList';
import Pillars from './Pillars/Pillars';

const EditReport = ({ id, title: initialTitle, getUpdated }) => {
    const token = window.localStorage.getItem("accessToken") || "";
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [title, setTitle] = useState(initialTitle);
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState(null);
    const [selectedData, setSelectedData] = useState([]);
    const user = JSON.parse(window.localStorage.getItem("user"))
    const location = useLocation();
    const { hash, pathname, search } = location;
    const [basicUiMenuOpen, setBasicUiMenuOpen] = useState(false)
    const [pillars, setPillars] = useState(false)
    const [region, setRegion] = useState(false)
    const [portfolio, setPortfolio] = useState(false)
    const [help, setHelp] = useState(false)
    const [menuList, setMenuList] = useState()
    const [subMenuList, setSubMenuList] = useState()
    
    const [reportName, setReportName] = useState("")
    const [selectedGeographiesData, setSelectedGeographiesData] = useState([])
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [includeImpactStories, setIncludeImpactStories] = useState(false)

    const [selectedTags, setSelectedTags] = useState([])
    const [selectedPillars, setSelectedPillars] = useState([])
    const [selectedActions, setSelectedActions] = useState([])
    const [selectedMetrics, setSelectedMetrics] = useState([])
    const [updateTrigger, setUpdateTrigger] = useState(0);

    const [nameRequired, setNameRequired] = useState(null);
    const [nameRegionRequired, setRegionRequired] = useState(null);
    const [startDateRequired, setStartDateRequired] = useState(null);
    const [endDateRequired, setEndDateRequired] = useState(null);

    useEffect(() => {
        if (isOpen) {
            setSelectedGeographiesData([])
            API.get(`/custom-country-report?report_id=${id}`, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((response) => {
                if (response.data.length > 0) {
                    setTitle(response.data[0].title || initialTitle);
                    setSelectedData(response.data[0])
                    setSelectedPillars(response.data[0]?.pillars)
                    setStartDate(response.data[0]?.start_date)
                    setEndDate(response.data[0]?.end_date)
                    const { metrics_data_tab, start_date, end_date, impact_story, regions, countries, states, cities } = response.data[0];
                    if (metrics_data_tab) {
                        const updatedGeographies = metrics_data_tab.map((geo) => ({
                            id: Date.now(),
                            region: geo.region || "",
                            regionId: regions.find((item) => item.name === geo.region)?.id || null,
                            country: geo.country || "",
                            countryId: countries.find((item) => item.name === geo.country)?.id || null,
                            state: geo.state || "",
                            stateId: states.find((item) => item.name === geo.state)?.id || null,
                            city: geo.city || "",
                            cityId: cities.find((item) => item.name === geo.city)?.id || null,
                        }));
                        setSelectedGeographiesData(updatedGeographies)
                        if (impact_story) {
                            setIncludeImpactStories(impact_story)
                        }
                    }
                }
            }).catch((error) => console.error("Error fetching report:", error));
        }
    }, [isOpen]); // Now fetches data only when the drawer is opened

    const saveField = () => {
        setIsLoading(true);
        setMessage(null);

        const formData = new FormData();
        formData.append("id", id);
        formData.append("title", title);

        API.put(`/custom-country-report`, formData, {
            headers: { Authorization: `Bearer ${token}` },
        })
        .then(() => {
            getUpdated();
            setMessage('Updated report title successfully');
            setIsLoading(false);
            onClose();
        })
        .catch(() => {
            setMessage('Failed to update report title');
            setIsLoading(false);
        });
    };

    const getTagsList=(tags)=>{
        setSelectedTags(tags)
    }
    const getPillarsList=(pillars)=>{
        setSelectedPillars(pillars)
    }
    const getActionsList=(actions)=>{
        setSelectedActions(actions)
    }

    const getMetricsList=(metrics)=>{
        setSelectedMetrics(metrics)
    }

    const getSelectedGeographies = (geographiesData) =>{
        setSelectedGeographiesData(geographiesData)
    }
    const getStartDate=(startDate)=>{
        setStartDate(startDate)
    }
    const getEndDate=(endDate)=>{
        setEndDate(endDate)
    }
    const getIncludeImpactStories=(event)=>{
        setIncludeImpactStories(event)
    }

    const saveReport = () => {
        const regions = [...new Set(selectedGeographiesData.map((item) => item.regionId).filter(Boolean))];
        const countries = [...new Set(selectedGeographiesData.map((item) => item.countryId).filter(Boolean))];
        const states = [...new Set(selectedGeographiesData.map((item) => item.stateId).filter(Boolean))];
        const cities = [...new Set(selectedGeographiesData.map((item) => item.cityId).filter(Boolean))];
        const formData = new FormData();
        if (cities.length > 0) formData.append("city_report", JSON.stringify(cities));
        if (states.length > 0) formData.append("state_report", JSON.stringify(states));
        if (countries.length > 0) formData.append("country_report", JSON.stringify(countries));
        if (regions.length > 0) formData.append("region_report", JSON.stringify(regions));
        if (selectedMetrics) formData.append("metric_report", JSON.stringify(selectedMetrics));
        if (selectedTags) formData.append("tag_report", JSON.stringify(selectedTags));
        if (selectedPillars) formData.append("pillar_report", JSON.stringify(selectedPillars.map(p => p.id)));
        if (selectedActions) formData.append("action_report", JSON.stringify(selectedActions));
        formData.append("id", id);
        formData.append("title", title);
        formData.append("start_date", startDate);
        formData.append("end_date", endDate);
        formData.append("impact_story", includeImpactStories);
        API.put(`/custom-country-report`,formData , {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then(response=>{
            getUpdated();
            setMessage('Updated report title successfully');
            setIsLoading(false);
            onClose();

        }).catch(error=>{
            onClose()
            setMessage('Failed to create report')
            setIsLoading(false)
        })
    };

    return (
        <>
            <EditIcon cursor="pointer" onClick={onOpen} />
            <Drawer isOpen={isOpen} placement="right" onClose={onClose} closeOnOverlayClick={false}>
                <DrawerOverlay />
                <DrawerContent maxW="50%">
                    <DrawerCloseButton />
                    <DrawerHeader bg="gray.200" fontWeight="normal" p="10px">
                        Edit {title}
                    </DrawerHeader>
                    <DrawerBody>
                        <FormControl mb="5px">
                            <FormLabel htmlFor="reportTitle">Report Title</FormLabel>
                            <Input 
                                id="reportTitle" 
                                name="reportTitle" 
                                type="text" 
                                placeholder="Enter report title" 
                                value={title} // Controlled input
                                onChange={(e) => setTitle(e.target.value)} 
                            />
                        </FormControl>
                        <RegionsList
                            selectedGeographiesData = {selectedGeographiesData}
                            startDate={startDate}
                            endDate={endDate}
                            selectedData={selectedData}
                            getSelectedGeographies={getSelectedGeographies}
                            getStartDate={getStartDate}
                            getEndDate={getEndDate}
                            includeImpactStories={getIncludeImpactStories}
                        />
                        <Pillars
                            id={id}
                            selectedPillars={selectedPillars}
                            getTagsList={getTagsList}
                            getPillarsList={getPillarsList}
                            getActionsList={getActionsList}
                            getMetricsList={getMetricsList}
                        />
                    </DrawerBody>
                    <DrawerFooter backgroundColor={'transparent'} borderTop={'1px solid #E2E8F0'}>
                        <Flex justify="flex-end" w="100%" gap={2}>
                            <Button variant="outline" onClick={onClose}>Cancel</Button>
                            <Button 
                                colorScheme="blackAlpha" 
                                bg="black" 
                                color="white" 
                                onClick={saveReport} 
                                isDisabled={!(includeImpactStories || (selectedMetrics.length > 0))}
                            > Save the report
                            </Button>
                        </Flex>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </>
    );
};

export default EditReport;
